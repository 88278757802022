
.close-btn {
  z-index: var(--z-close-button);
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 39px !important;
  text-align: center;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-radius: 20px;
  outline: none;
  transition: color var(--transition);

  &:focus {
    box-shadow: 0 0 0 3px var(--input-focus-color);
  }

  &.white {
    color: var(--gray-0);
  }

  &.black {
    color: var(--gray-80);
    background-color: var(--gray-0);
    border: 1px solid var(--gray-80);
  }

  &.green {
    color: var(--viridian-50);

    &:hover:not([disabled]) {
      color: var(--viridian-60);
    }
  }
}
